<template>
  <v-layout row wrap>
    <v-flex v-for="(field,index) in fields" :key="index" :class="field.class">
      <div v-if="field.type == 'autocomplete'">
        <v-autocomplete
          v-model="model[field.model]"
          :label="field.label"
          :items="field.items"
          :rules="getRules(field)"
          :class="field.classField"
          :disabled="field.disabled || !edit"
          
          clearable
        ></v-autocomplete>
      </div>
      <div v-else-if="field.type == 'select'">
        <v-select
          v-model="model[field.model]"
          :label="field.label"
          :items="field.items"
          :rules="getRules(field)"
          :class="field.classField"
          :disabled="field.disabled || !edit"
          :placeholder="field.placeholder"
          
          clearable
        ></v-select>
      </div>
      <div v-else-if="field.type == 'date'">
        <DateField
          :date="model[field.model]"
          :propiedad="field.model"
          :birthDay="field.birthday"
          :classField="field.classField"
          :min="field.min?model[field.min]:'1930-01-01'"
          :label="field.label"
          :edit="field.disabled || !edit"
          :rules="getRules(field)"
          @cambiarProp="cambiarProp"
        />
      </div>
      <div v-else-if="field.type == 'textarea'">
        <v-textarea
          v-model="model[field.model]"
          :label="field.label"
          :required="field.required"
          :readonly="field.readonly"
          :disabled="field.disabled"
          :class="field.classField"
          :rules="getRules(field)"
          auto-grow
          :rows="field.rows"
        ></v-textarea>
      </div>
      <div v-else-if="field.type == 'number'">
        <v-text-field
          v-model="model[field.model]"
          :label="field.label"
          :disabled="field.disabled || !edit"
          :rules="getRules(field)"
          :class="field.classField"
          :hint="field.hint"
          :persistent-hint="field.persistenthint"
          type="number"
          :counter="field.counter"
        ></v-text-field>
      </div>
      <div v-else>
        <v-text-field
          v-model="model[field.model]"
          :label="field.label"
          :disabled="field.disabled || !edit"
          :rules="getRules(field)"
          :class="field.classField"
          :hint="field.hint"
          :persistent-hint="field.persistenthint"
        >
          <template v-if="field.prepend" slot="prepend">
            <img :src="field.prepend.img" width="25px">
          </template>
        </v-text-field>
        <v-alert v-if="field.type != 'text'" color="error" icon="warning" value="true">
          <strong>The {{field.type}} type is not yet implemented.</strong>
          <br>
          {{field}}
        </v-alert>
      </div>
    </v-flex>
  </v-layout>
</template>
<script>
import DateField from "@/common/util/crud/DateField";
export default {
  props: {
    modelo: Object,
    fields: Array,
    edit: Boolean,
    create: Boolean
  },
  components: { DateField },
  data() {
    return {
      originalModel: this.modelo,
      consulta: false
    };
  },
  computed: {
    model() {
      return this.modelo;
    }
  },
  methods: {
    cambiarProp(val) {
      this.model[val.campo] = val.valor;
    },
    getRules(field){
      var array=[]
      if (!field.rules) return array;
      const arules=field.rules.split(" ");
      arules.forEach(element => {
        if(element=='required')
        {
          array.push(v=>!!v||'Este Campo es Obligatorio');
        }
        if(element=='email')
        {
          array.push( v => /.+@.+/.test(v) || 'Correo no valido');
        }
        if(element=='requiredSelect'){
          array.push(v => (!!v) || "Debe elegir al menos una opcion")
        }
        if(element=='direccion'){
             array.push(v => /^.+\/.+\/.+/.test(v) || "Debes tener 3 datos separados por /")
        }
        if(element=='count'){
          array.push(v=>!!v && v.length<=field.counter  || `Este campo no puede tener mas de ${field.counter} caracteres`)
        }
        if(element=='celular'){
            const re = /^[0-9]/
            array.push(v =>(re.test(String(v).toLowerCase()) && v.length === 8) || !v || 'Introduzca un numero válido.')
        }
      });
      return array;
    }
  }
};
</script>
<style>
.required label::after {
  content: " *";
  color: red;
}
</style>


